<template>
  <div class="customerManage">
    <div class="facilityBox">
      <div class="nums">
        <div
          class="nums-item"
          v-for="item in typeList"
          :key="item.Value"
          @click="searchNum(item.Value)"
        >
          <img :src="item.pic" />
          <div class="num-content">
            <span style="font-size: 20px;font-weight: bold;color: #fff;">{{
              item.Label
            }}</span>
            <span
              style="font-size: 18px;font-weight: bold;color: #fff;margin-top:12px;"
              >{{ item.Num }}</span
            >
          </div>
        </div>
      </div>
      <el-tabs v-model="activePage" class="barWrap" @tab-click="switchTab">
        <el-tab-pane label="代理商" name="1"></el-tab-pane>
        <el-tab-pane label="意向客户" name="2"></el-tab-pane>
      </el-tabs>
      <!-- 搜索表单 -->
      <el-form ref="searchForm" inline :model="searchForm">
        <el-form-item
          :label="activePage == 1 ? '代理商名称：' : '客户名称：'"
          prop="searchContent"
          label-width="100px"
        >
          <el-input
            v-model="searchForm.searchContent"
            :placeholder="
              activePage == 1 ? '请输入代理商名称' : '请输入客户名称'
            "
          >
          </el-input>
        </el-form-item>
        <el-form-item label="提交日期：" prop="time" label-width="100px">
          <el-date-picker
            v-model="searchForm.time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            end-placeholder="结束时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-button
          type="primary"
          icon="el-icon-zoom-in"
          @click="search(), getReport()"
          >搜索</el-button
        >
        <el-button
          icon="el-icon-delete"
          @click="
            (searchForm = JSON.parse(JSON.stringify(newSearchForm))), search()
          "
          >清空</el-button
        >
      </el-form>
      <!-- 表格 -->
      <el-table
        :data="pagination.list"
        v-loading="pagination.loading"
        :header-cell-style="{ background: '#f0f0f0', color: '#666' }"
      >
        <el-table-column
          align="center"
          prop="index"
          label="序号"
          width="50"
          fixed="left"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.index }}
          </template>
        </el-table-column>
        <!-- 代理商 -->
        <div v-if="activePage == 1">
          <el-table-column
            align="center"
            prop="AgentName"
            label="姓名"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="CompanyName"
            label="公司名称"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="AgentPhone"
            label="手机号"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="CompanyAddress"
            label="公司地址"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="SubmitDate"
            label="添加时间"
            show-overflow-tooltip
          >
          </el-table-column>
        </div>
        <!-- 意向客户 -->
        <div class="abcdefg" v-if="activePage == 2" :key="searchForm.status">
          <!-- 全部、新增、待跟进、已成交 -->
          <div
            v-if="
              searchForm.status == 0 ||
                searchForm.status == 1 ||
                searchForm.status == 4 ||
                searchForm.status == 7
            "
          >
            <el-table-column
              align="center"
              prop="Name"
              label="客户公司名称"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="ContactsPhone"
              label="手机号"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="Status"
              label="客户状态"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <el-tag v-if="scope.row.AscriptionUserID == 0">公共客户</el-tag>
                <!-- 0待跟进；1更进中；2流失；3成交 -->
                <el-tag
                  v-else
                  :type="
                    scope.row.Status == 0 || scope.row.Status == 1
                      ? 'warning'
                      : scope.row.Status == 2
                      ? 'info'
                      : 'success'
                  "
                  >{{
                    scope.row.Status == 0
                      ? "待跟进"
                      : scope.row.Status == 1
                      ? "跟进中"
                      : scope.row.Status == 2
                      ? "已流失"
                      : "已成交"
                  }}</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="AgentName"
              label="所属代理"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="Province,City,County,Address"
              label="公司地址"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.Province }}{{ scope.row.City }}{{ scope.row.County
                }}{{ scope.row.Address }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="AddTime"
              label="添加时间"
              show-overflow-tooltip
            >
            </el-table-column>
          </div>
          <!-- 今日跟进 -->
          <div v-if="searchForm.status == 2">
            <el-table-column
              align="center"
              prop="Name"
              label="客户公司名称"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="Status"
              label="客户状态"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <el-tag v-if="scope.row.AscriptionUserID == 0">公共客户</el-tag>
                <!-- 0待跟进；1更进中；2流失；3成交 -->
                <el-tag
                  v-else
                  :type="
                    scope.row.Status == 0 || scope.row.Status == 1
                      ? 'warning'
                      : scope.row.Status == 2
                      ? 'info'
                      : 'success'
                  "
                  >{{
                    scope.row.Status == 0
                      ? "待跟进"
                      : scope.row.Status == 1
                      ? "跟进中"
                      : scope.row.Status == 2
                      ? "已流失"
                      : "已成交"
                  }}</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="AgentName"
              label="所属代理"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="FollowupTypeName"
              label="跟进方式"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="FollowupCotent"
              label="跟进内容"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="FollowTime"
              label="跟进时间"
              show-overflow-tooltip
            >
            </el-table-column>
          </div>
          <!-- 跟进中 -->
          <div v-if="searchForm.status == 3">
            <el-table-column
              align="center"
              prop="Name"
              label="客户公司名称"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="Status"
              label="客户状态"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <el-tag v-if="scope.row.AscriptionUserID == 0">公共客户</el-tag>
                <!-- 0待跟进；1更进中；2流失；3成交 -->
                <el-tag
                  v-else
                  :type="
                    scope.row.Status == 0 || scope.row.Status == 1
                      ? 'warning'
                      : scope.row.Status == 2
                      ? 'info'
                      : 'success'
                  "
                  >{{
                    scope.row.Status == 0
                      ? "待跟进"
                      : scope.row.Status == 1
                      ? "跟进中"
                      : scope.row.Status == 2
                      ? "已流失"
                      : "已成交"
                  }}</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="AgentName"
              label="所属代理"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="FollowupCounts"
              label="跟进次数"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="UpEndTime"
              label="最后跟进时间"
              show-overflow-tooltip
            >
            </el-table-column>
          </div>
          <!-- 代理流失 -->
          <div v-if="searchForm.status == 5">
            <el-table-column
              align="center"
              prop="Name"
              label="客户公司名称"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="Status"
              label="客户状态"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <el-tag v-if="scope.row.AscriptionUserID == 0">公共客户</el-tag>
                <!-- 0待跟进；1更进中；2流失；3成交 -->
                <el-tag
                  v-else
                  :type="
                    scope.row.Status == 0 || scope.row.Status == 1
                      ? 'warning'
                      : scope.row.Status == 2
                      ? 'info'
                      : 'success'
                  "
                  >{{
                    scope.row.Status == 0
                      ? "待跟进"
                      : scope.row.Status == 1
                      ? "跟进中"
                      : scope.row.Status == 2
                      ? "已流失"
                      : "已成交"
                  }}</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="LastLostAgentName"
              label="流失代理"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="AgentName"
              label="转入代理"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="LastLostContent"
              label="流失原因"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="LostTimes"
              label="流失次数"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="LastLostTime"
              label="最后流失时间"
              show-overflow-tooltip
            >
            </el-table-column>
          </div>
          <!-- 公共客户 -->
          <div v-if="searchForm.status == 6">
            <el-table-column
              align="center"
              prop="Name"
              label="客户公司名称"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="ContactsPhone"
              label="手机号"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="Status"
              label="客户状态"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <el-tag v-if="scope.row.AscriptionUserID == 0">公共客户</el-tag>
                <!-- 0待跟进；1更进中；2流失；3成交 -->
                <el-tag
                  v-else
                  :type="
                    scope.row.Status == 0 || scope.row.Status == 1
                      ? 'warning'
                      : scope.row.Status == 2
                      ? 'info'
                      : 'success'
                  "
                  >{{
                    scope.row.Status == 0
                      ? "待跟进"
                      : scope.row.Status == 1
                      ? "跟进中"
                      : scope.row.Status == 2
                      ? "已流失"
                      : "已成交"
                  }}</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="IntoTimes"
              label="转入次数"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="Province,City,County,Address"
              label="公司地址"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.Province }}{{ scope.row.City }}{{ scope.row.County
                }}{{ scope.row.Address }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="LastLostTime"
              label="最后流失时间"
              show-overflow-tooltip
            >
            </el-table-column>
          </div>
        </div>
        <el-table-column fixed="right" align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="goAgentList(scope.row)"
              v-if="searchForm.status != 6 && scope.row.AscriptionUserID != 0"
              >查看代理</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="$refs.ctrDetail.customerDetail(scope.row)"
              v-if="activePage == 2"
            >
              查看客户
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div style="display:flex; align-items:center;margin-top: 10px">
        <el-pagination background
          background
          class="pagination"
          @current-change="handleCurrentChange"
          :current-page.sync="pagination.page"
          :page-size="pagination.pagesize"
          layout="total, prev, pager, next, jumper"
          :total="pagination.total"
        >
        </el-pagination>
      </div>
    </div>

    <customer-detail ref="ctrDetail"></customer-detail>
  </div>
</template>

<script>
import {
  GetAgentList,
  GetCustomerList,
  GetLostRecord,
  GetReport,
  GetFollowUpList,
} from "@/api/agentManage/customerManage";
import { json } from "body-parser";
import customerDetail from "./customerDetail";
export default {
  data() {
    return {
      activePage: "1",
      typeList: [
        {
          Label: "全部客户",
          Value: 0,
          Num: 0,
          pic: require("@/assets/image/agentManage-pic/all.png"),
        },
        {
          Label: "今日新增",
          Value: 1,
          Num: 0,
          pic: require("@/assets/image/agentManage-pic/add.png"),
        },
        {
          Label: "今日跟进",
          Value: 2,
          Num: 0,
          pic: require("@/assets/image/agentManage-pic/follow.png"),
        },
        {
          Label: "跟进中",
          Value: 3,
          Num: 0,
          pic: require("@/assets/image/agentManage-pic/following.png"),
        },
        {
          Label: "已成交",
          Value: 4,
          Num: 0,
          pic: require("@/assets/image/agentManage-pic/deal.png"),
        },
        {
          Label: "代理流失",
          Value: 5,
          Num: 0,
          pic: require("@/assets/image/agentManage-pic/lost.png"),
        },
        {
          Label: "待跟进",
          Value: 7,
          Num: 0,
          pic: require("@/assets/image/agentManage-pic/nofollow.png"),
        },
        {
          Label: "公共客户",
          Value: 6,
          Num: 0,
          pic: require("@/assets/image/agentManage-pic/public.png"),
        },
      ],
      searchForm: {
        searchContent: "", //名称
        status: 0, //数据筛选
        time: ["", ""],
      },
      newSearchForm: {
        searchContent: "", //名称
        status: 0, //数据筛选
        time: ["", ""],
      },
      pagination: {
        // 主页分页
        page: 1,
        pagesize: 10,
        total: 0,
        loading: false,
        list: [],
      },
    };
  },
  components: {
    "customer-detail": customerDetail,
  },
  methods: {
    // 获取报表数据
    getReport() {
      GetReport().then((res) => {
        let nums = res.data || {};
        let numsArr = [
          nums.All,
          nums.ToDayAdd,
          nums.ToDayUp,
          nums.Uping,
          nums.Deal,
          nums.Lose,
          nums.AwitUp,
          nums.Public,
        ];
        this.typeList = this.typeList.map((i, idx) => {
          i.Num = numsArr[idx];
          return i;
        });
      });
    },

    //获取统计列表
    getTableList() {
      let ajaxFun = this.activePage == 1 ? GetAgentList : GetCustomerList;
      this.pagination.loading = true;
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        searchContent: this.searchForm.searchContent,
        status: this.searchForm.status,
        startTime: this.searchForm.time ? this.searchForm.time[0] : "",
        endTime: this.searchForm.time ? this.searchForm.time[1] : "",
      };
      ajaxFun(params)
        .then((res) => {
          this.pagination.list = res.data.DataList.map((item, index) => {
            item.index =
              (this.pagination.page - 1) * this.pagination.pagesize + index + 1;
            return item;
          });
          this.pagination.total = Number(res.data.TotalCount);
        })
        .finally(() => {
          this.pagination.loading = false;
        });
    },

    //搜索
    search() {
      this.pagination.loading = true;
      this.pagination.page = 1;
      this.pagination.list = [];
      this.getTableList();
    },

    switchTab() {
      this.searchForm = {
        searchContent: "", //名称
        status: 0, //数据筛选
        time: ["", ""],
      };
      this.search();
    },

    // 点击数量
    searchNum(type) {
      this.searchForm = JSON.parse(JSON.stringify(this.newSearchForm));
      this.activePage = "2";
      this.searchForm.status = type;
      this.pagination.loading = true;
      this.pagination.page = 1;
      this.pagination.list = [];
      this.getTableList();
    },

    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.pagination.loading = true;
      this.pagination.page = e;
      this.getTableList();
    },

    // 查看代理
    goAgentList(item) {
      this.$router.push({
        path: "/agentManage/customerManage/agentInfo",
        query: {
          searchContent: this.searchForm.searchContent,
          status: this.searchForm.status,
          activePage: this.activePage,
          startTime: this.searchForm.time ? this.searchForm.time[0] : "",
          endTime: this.searchForm.time ? this.searchForm.time[1] : "",
          itemInfo: JSON.stringify(item),
        },
      });
    },

    // 浏览器刷新重置参数
    beforeunloadHandler(e) {
      if (this.$route.path == "/agentManage/customerManage/index") {
        // 重置路由
        this.$router.push({
          path: "/agentManage/customerManage/index",
        });
      }
    },
  },
  mounted() {
    // 监听浏览器刷新
    window.addEventListener("beforeunload", (e) => this.beforeunloadHandler(e));
  },
  destroyed() {
    // 移除浏览器刷新
    window.removeEventListener("beforeunload", (e) =>
      this.beforeunloadHandler(e)
    );
  },
  created() {
    this.pagination.page = this.$route.query.pageIndex
      ? Number(this.$route.query.pageIndex)
      : 1;
    this.activePage = this.$route.query.activePage
      ? this.$route.query.activePage
      : "1";
    this.searchForm.searchContent = this.$route.query.searchContent
      ? this.$route.query.searchContent
      : "";
    this.searchForm.status = this.$route.query.status
      ? this.$route.query.status
      : 0;
    this.searchForm.time = [
      this.$route.query.startTime ? this.$route.query.startTime : "",
      this.$route.query.endTime ? this.$route.query.endTime : "",
    ];
    this.getTableList();
    this.getReport();
  },
};
</script>

<style lang="scss" scoped>
.customerManage {
  .nums {
    margin: 16px;
    display: flex;
    align-items: center;

    .nums-item {
      margin-right: 56px;
      position: relative;

      .num-content {
        position: absolute;
        top: 37px;
        left: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}

/deep/.el-drawer__header {
  align-items: center;
  color: #000000;
  display: flex;
  margin-bottom: 16px;
  padding: 12px 20px 0;
  font-weight: bold;
}
</style>
